import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Benefits.styles";
import clsx from "clsx";
import { motion } from 'framer-motion'
export function Benefits() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<motion.img
				className={classes.decoration}
				src="/assets/images/decoration_home_1.svg"
				alt=""
				// data-sal="slide-up"
				// data-sal-delay="0"
				// data-sal-duration="1500"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.6, type: "tween" }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
			/>
			<motion.img
				className={classes.decoration}
				src="/assets/images/decoration_home_2.svg"
				alt=""
				// data-sal="slide-up"
				// data-sal-delay="0"
				// data-sal-duration="1500"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.6, type: "tween" }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
			/>
			<Grid
				item
				container
				justifyContent="center"
				// data-sal="slide-up"
				// data-sal-delay="0"
				// data-sal-duration="1500"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.6, type: "tween" }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				<Typography
					color="primary"
					className={classes.title}
					component="h2"
				>
					Como un banco, pero mejor
				</Typography>
			</Grid>
			<Grid item container className={classes.item}>
				<Grid item md={6}>
					<motion.img
						className={classes.image}
						src="/assets/images/application_online.webp"
						alt="Solicitud en línea sin tanto papeleo."
						// data-sal="slide-right"
						// data-sal-delay="0"
						// data-sal-duration="1000"
						// data-sal-easing="ease"
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.6, type: "tween" }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
					/>
				</Grid>
				<Grid
					item
					md={6}
					className={classes.itemInfoContainer}
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.6, type: "tween" }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
				>
					<Typography className={classes.itemTitle} component="h3">Solicitud en línea sin tanto papeleo.</Typography>
					<Typography className={classes.itemDescription}>No más largas filas en sucursales, en Kredi llevamos todo tu proceso en línea. Olvídate de perder tiempo en trámites presenciales.</Typography>
				</Grid>
			</Grid>
			<Grid item container className={clsx(classes.item, classes.itemReverse)}>
				<Grid
					item
					md={6}
					className={classes.itemInfoContainer}
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.6, type: "tween" }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
				>
					<Typography className={classes.itemTitle} component="h3">Mayores posibilidades de obtener tu crédito.</Typography>
					<Typography className={classes.itemDescription}>En Kredi sabemos lo difícil que es conseguir un crédito hipotecario. Por lo que hemos decidido ser más flexibles con algunos requisitos.</Typography>
				</Grid>
				<Grid item md={6} className={classes.imageRight} >
					<motion.img
						className={classes.image}
						src="/assets/images/flexibility.webp"
						alt="Crédito con una mayor flexibilidad."
						// data-sal="slide-right"
						// data-sal-delay="0"
						// data-sal-duration="1000"
						// data-sal-easing="ease"
						initial={{ opacity: 0, x: 100 }}
						transition={{ duration: 1.6, type: "tween" }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
					/>
				</Grid>
			</Grid>
			<Grid item container className={classes.item}>
				<Grid item md={6} >
					<motion.img
						className={classes.image}
						src="/assets/images/personalized_attention.webp"
						alt="Recibe atención personalizada."
						// data-sal="slide-right"
						// data-sal-delay="0"
						// data-sal-duration="1000"
						// data-sal-easing="ease"
						initial={{ opacity: 0, x: -100 }}
						transition={{ duration: 1.6, type: "tween" }}
						whileInView={{ opacity: 1, x: 0 }}
						viewport={{ once: true }}
					/>
				</Grid>
				<Grid
					item
					md={6}
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.6, type: "tween" }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
					className={classes.itemInfoContainer}
				>
					<Typography className={classes.itemTitle} component="h3">Recibe atención personalizada.</Typography>
					<Typography className={classes.itemDescription}>Un experto hipotecario te acompañará durante todo el proceso para a resolver todas las dudas que puedas tener.</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}
