import React from "react"
import { FAQ, Footer, Testimonial } from "../components"
import { Hero, Benefits, CreditExperince, HowItWorks, Highlights, } from "../components/Home"

import { Layout } from "../layout"

function Index({ location }: any) {
	return (
		<Layout location={location}>
			<Hero />
			<Benefits />
			<Testimonial containerProps={{ style: { padding: '50px 72px 138px 72px' } }} />
			<CreditExperince />
			<HowItWorks />
			<FAQ />
			<Highlights />
			<Footer alternative title="Un crédito hipotecario cómo nunca antes se había visto" />
		</Layout>
	)
}

export default Index
