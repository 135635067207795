import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: 886,
		boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
		overflow: 'hidden',
		[theme.breakpoints.down(1024)]: {
			maxHeight: '978px',
			minHeight: '978px',
			position: 'relative',

		}
	},
	container: {
		padding: '0 60px',
		paddingTop: 115,
		paddingRight: 10,
		flexBasis: '52%',
		maxWidth: '52%',
		gap: 16,
		[theme.breakpoints.down(1024)]: {
			flexBasis: '100%',
			maxWidth: '100%',
			padding: "0 16px",
			paddingTop: 0,
			marginTop: 61,
			position: 'relative'
		},
		[theme.breakpoints.between(1024, 1440)]: {
			paddingTop: 60,
			paddingRight: 0,
			paddingLeft: 40,
			maxWidth: '50%',

		},
		[theme.breakpoints.up('xl')]: {
			alignItems: 'flex-start'
		}

	},
	imageContainer: {
		flexBasis: '48%',
		display: 'flex',
		justifyContent: 'flex-end',
		[theme.breakpoints.down(1024)]: {
			width: '100%',
			flexBasis: '100%',
			overflow: 'hidden',
			position: 'absolute',
			top: "unset",
			bottom: "0",
			zIndex: -2,
			height: 555,
			minHeight: 55,
			background: 'url("/assets/images/hero_home_portrait.webp")',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'bottom'
		},
		[theme.breakpoints.between('sm', 1440)]: {
			background: 'url("/assets/images/hero_image.webp")',
			flexBasis: '50%',
			backgroundSize: 'cover'

		},
		[theme.breakpoints.between(1024, 1440)]: {
			position: 'unset'
		}

	},
	image: {
		width: 683,
		height: 866,
		[theme.breakpoints.down(1439)]: {
			display: 'none'
		},
		[theme.breakpoints.down(1440)]: {
			display: 'none'
		},
		[theme.breakpoints.up(1440)]: {
			width: "100%",
			height: "100%",
		}
	},
	imageMobile: {
		width: '150%',
		height: 639,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		},

	},
	title: {
		fontSize: 60,
		lineHeight: '76px',
		fontWeight: 'bold',
		[theme.breakpoints.down(1024)]: {
			fontSize: 40,
			lineHeight: '45px',
			textAlign: 'center'
		}

	},
	description: {
		fontSize: 24,
		lineHeight: '32px',
		letterSpacing: ' -0.011em',
		[theme.breakpoints.down(1024)]: {
			fontSize: 18,
			lineHeight: '28px',
			textAlign: 'center'
		}
	},
	buttonsContainer: {
		gap: 20,
		marginTop: 24,
		[theme.breakpoints.down(1024)]: {
			gap: 20,
			justifyContent: 'center'
		},
		['@media (max-width: 380px)']: {
			gap: 5,
		}
	},
	buttonItem: {
		['@media (max-width: 380px)']: {
			width: '49%'
		}
	},
	apply: {
		height: 52,
		width: 174,
		fontSize: 18,
		['@media (max-width: 380px)']: {
			width: '100%',
			padding: '14px 16px',
			fontSize: 16,


		}

	},
	quotation: {
		height: 52,
		width: 174,
		fontSize: 18,
		padding: "14px 16px",
		['@media (max-width: 380px)']: {
			width: '100%',
			padding: "14px 8px",
			fontSize: 16,
		}

	},
	regulationsContainer: {
		marginTop: 230,
		marginBottom: 20,
		[theme.breakpoints.down(1024)]: {
			marginTop: 0,
			marginBottom: 0,
			position: 'absolute',
			bottom: "0",
			padding: '22px 16px',
			background: 'linear-gradient(180deg, rgba(36, 44, 55, 0) 0%, rgba(36, 44, 55, 0.77) 100%);'
		}
	},
	regulationsTitle: {
		fontSize: 18,
		[theme.breakpoints.down(1024)]: {
			color: 'white',
			fontSize: 18,
			lineHeight: '20px'
		}
	},
	imagesContainer: {
		marginTop: 26,
		gap: 20,
		[theme.breakpoints.down(1024)]: {
			marginTop: 12,
			gap: 0,
			//justifyContent: 'space-between',
		}

	},
	cnbv: {
		width: 162,
		height: 66,
		[theme.breakpoints.down(1024)]: {
			display: 'none'

		}
	},
	cnbvWhite: {
		width: 153,
		height: 63,
		[theme.breakpoints.up('md')]: {
			display: 'none'

		}
	},
	condusef: {
		width: 107,
		height: 56,
		[theme.breakpoints.down(1024)]: {
			display: 'none'

		}
	},
	condusefWhite: {
		width: 102,
		height: 55,
		[theme.breakpoints.up('md')]: {
			display: 'none'

		}
	},
	buro: {
		width: 52,
		height: 52,
		[theme.breakpoints.down(1024)]: {
			display: 'none'

		}
	},
	buroWhite: {
		width: 52,
		height: 52,
		[theme.breakpoints.up('md')]: {
			display: 'none'

		}
	},
	backgroundWhite: {
		background: "linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 39.16%)",
		position: 'absolute',
		width: '100%',
		height: '151px',
		top: '35%',
		zIndex: -1,
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}

	}
}))