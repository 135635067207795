import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: 'url("/assets/images/background-home-credits.webp")',
		backgroundColor: 'rgba(117, 139, 183, 0.08);',
		padding: '64px 144px',
		paddingBottom: 176,
		[theme.breakpoints.down('xs')]: {
			padding: '64px 16px',
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			padding: '64px 16px',


		},

	},
	title: {
		fontWeight: 'bold',
		fontSize: 50,
		lineHeight: '54px',
		marginBottom: 64,
		[theme.breakpoints.down('sm')]: {
			fontSize: 32,
			lineHeight: '40px',
			textAlign: 'center'
		}
	},
	cardsContainer: {
		justifyContent: 'space-evenly',
		gap: 20,
		[theme.breakpoints.down('sm')]: {
			gap: 40,
		},
	},
	cardItem: {
		//MOBILE
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		//TABLE
		[theme.breakpoints.between('sm', 'md')]: {
			justifyContent: 'center',
			display: 'flex'

		},
	},
	card: {
		width: 520,
		height: 731,
		position: 'relative',
		boxShadow: ' 0px 5px 22px rgba(57, 74, 97, 0.12)',

		//MOBILE
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			height: "100%",
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: 703,
			height: "100%",
		},

	},
	cardContainer: {
		padding: "17px 32px",
		gap: 16,
	},
	imageContainer: {
		width: 520,
		height: 325,
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			height: 'unset',
			aspecRatio: '16/9'
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: 703,
			height: 325
		},
	},
	image: {
		width: 520,
		height: 325,
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			height: 'unset',
			aspectRatio: '16/9'
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: 703,
			//height: 325
		},

	},

	improveImageContainer: {
		width: 520,
		height: 325,
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			height: 'unset',
			aspecRatio: '16/9'
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: 703,
			height: 325,
			background: "url(/assets/images/mejora_tu_hipoteca_md.jpg)",
			backgroundSize: 'cover',
			backgroundPosition: 'bottom'
		},
	},

	improveImage: {
		width: 520,
		height: 325,
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			height: 'unset',
			aspectRatio: '16/9'
		},
		//TABLET
		[theme.breakpoints.between('sm', 'md')]: {
			width: 703,
			//height: 325
			display: 'none'
		},

	},

	cardTitle: {
		fontSize: 34,
		lineHeight: '40px',
		fontWeight: 'bold',
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			color: '#000',
			fontSize: 23,
			lineHeight: '28px',
		}
	},
	cardDecription: {
		fontSize: 18,
		lineHeight: '38px',
		fontWeight: 400,
		[theme.breakpoints.down('sm')]: {
			fontSize: 18,
			lineHeight: '38px',
		}
	},
	cardList: {
		marginBlock: 0,
		marginInline: 0,
		paddingInlineStart: '16px',
	},
	cardListItem: {
		color: theme.palette.primary.main
	},
	cardActions: {
		marginTop: 24,
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			marginBottom: 24,
		}

	},
	cardSeeMore: {
		fontWeight: 500,
		fontSize: 16,
		lineHeight: "16px",
		display: 'flex',
		alignItems: 'center',
		gap: 9,
		padding: 8,
		"&:hover": {
			gap: 18,
			backgroundColor: "rgba(0, 0, 0, 0.04)",
			borderRadius: 55,
		},
		"& img": {
			width: 22,
			height: 14,
		},
		[theme.breakpoints.down(1024)]: {
			position: 'unset',

		}
	},
	marginBottomTitle: {
		marginBottom: 32
	}
}))