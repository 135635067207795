import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: '100px 0',
		paddingBottom: 0,
		position: 'relative',
		[theme.breakpoints.down('md')]: {
			padding: '86px 16px',
			paddingBottom: '8px',
		},
	},
	title: {
		fontSize: 50,
		lineHeight: '54px',
		fontWeight: 900,
		[theme.breakpoints.down('md')]: {
			fontSize: 32,
			lineHeight: '40px',
			textAlign: 'center',
			marginBottom: 42
		}
	},
	item: {
		// LAPTOP - 1440px
		padding: '127px 70px',

		//LARGE SIZE > 1440px
		[theme.breakpoints.up(1719)]: {
			display: 'grid',
			gridTemplateColumns: '40% 60%'
		},

		//TABLET - 758px
		[theme.breakpoints.between('sm', 'md')]: {
			display: 'grid',
			gridTemplateColumns: '50% 50%',
			padding: '33px 15px',
			alignItems: 'center',
			gap: 10,

		},
		//MOBILE
		[theme.breakpoints.down('xs')]: {

			padding: '33px 0',
			justifyContent: 'center'

		},

	},
	itemReverse: {
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column-reverse'

		},
		[theme.breakpoints.up(1719)]: {
			gridTemplateColumns: '60% 40%'
		}
	},
	itemInfoContainer: {
		paddingTop: 70,
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			paddingTop: 24,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		//TABLET - 758px
		[theme.breakpoints.between('sm', 'md')]: {
			paddingTop: 0,
			maxWidth: "100%"
		},
		//LARGE SIZE
		[theme.breakpoints.up(1719)]: {
			maxWidth: '100%'
		}
	},
	itemTitle: {
		fontWeight: 'bold',
		fontSize: 40,
		lineHeight: '54px',
		//TABLET - 758px
		[theme.breakpoints.between('sm', 'md')]: {
			fontSize: 24,
			lineHeight: '36px',
			textAlign: 'left'

		},
		//MOBILE
		[theme.breakpoints.down('xs')]: {

			textAlign: 'center',
			fontSize: 24,
			lineHeight: '36px',

		},

	},
	itemDescription: {
		marginTop: 24,
		fontSize: 24,
		lineHeight: '44px',
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			fontSize: 18,
			lineHeight: '32px',
			textAlign: 'center',
			marginBottom: 55,
			maxWidth: 343,
		},
		//TABLET - 758px
		[theme.breakpoints.between('sm', 'md')]: {
			textAlign: 'left',
			fontSize: 18,
			lineHeight: '32px',
		},
	},
	image: {
		width: 560,
		height: 414,
		borderRadius: 8,
		[theme.breakpoints.down('md')]: {
			width: 343,
			height: 253,
			textAlign: 'center'
		},
		//TABLET - 758px
		[theme.breakpoints.between(600, 750)]: {
			width: "100%",
			height: "100%"
		},
	},
	imageRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		//MOBILE
		[theme.breakpoints.down('xs')]: {
			justifyContent: 'center',

		},
		//TABLET - 758px
		[theme.breakpoints.between('sm', 'md')]: {
			maxWidth: "100%"
		},
		//TABLET - 758px
		[theme.breakpoints.between(600, 700)]: {
			width: "100%",
			height: "100%"
		},
		//LARGE
		[theme.breakpoints.up(1719)]: {
			maxWidth: '100%'
		}
	},
	decoration: {
		position: 'absolute',

		"&:first-child": {
			top: 425,
		},
		"&:nth-child(2)": {
			top: 995,
			right: 0,

		},
		[theme.breakpoints.down('md')]: {
			"&:first-child": {
				top: 305,
				height: 178,
				width: 107,
				left: 0
			},
			"&:nth-child(2)": {
				display: 'none'
			},


		}
	}

}))