import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from './Hero.styles';
import { Button } from '../../';
import clsx from 'clsx';
import { motion } from 'framer-motion'
export function Hero() {
	const classes = useStyles()
	return (
		<Grid
			container
			className={classes.root}
			initial={{ opacity: 0, x: -1000 }}
			transition={{ duration: 1.42 }}
			animate={{ opacity: 1, x: 0 }}
			viewport={{ once: true }}
			component={motion.div}
		>
			<Grid
				item
				md={6}
				sm={12}
				container
				direction="column"
				alignItems="center"
				className={classes.container}>
				<Typography
					color="primary"
					className={classes.title}
					component="h1"
				>
					Un crédito hipotecario sin complicaciones
				</Typography>
				<Typography
					className={classes.description}>
					Hacemos fácil lo que siempre había sido complicado al momento de solicitar un crédito hipotecario.
				</Typography>
				<Grid container direction="row" className={classes.buttonsContainer}>
					<Grid
						item
						className={classes.buttonItem}>
						<Button
							to={`${process.env.GATSBY_KREDI_APP_BASE_URL}/`}
							useNativeAnchor
							color="primary"
							variant="contained"
							size="medium"
							target="_self"
							className={classes.apply}>
							Solicitar ahora
						</Button>
					</Grid>

				</Grid>
				<Grid
					container
					className={classes.regulationsContainer}>
					<Typography className={classes.regulationsTitle}>Nuestras autoridades:</Typography>
					<Grid container direction="row" alignItems="center" className={classes.imagesContainer} >
						<Grid item>
							<img
								className={classes.cnbv}
								src="/assets/images/CNBV.svg"
								alt="Logo de la Comisión Nacional Bancaria y de Valores"
							/>
							<img
								className={classes.cnbvWhite}
								src="/assets/images/CNBV_white.svg"
								alt="Logo de la Comisión Nacional Bancaria y de Valores"
							/>
						</Grid>
						<Grid item>
							<img
								className={classes.condusef}
								src="/assets/images/condusef.svg"
								alt="Logo de la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros"
							/>
							<img
								className={classes.condusefWhite}
								src="/assets/images/condusef_white.svg"
								alt="Logo de la Comisión Nacional Bancaria y de Valores"
							/>
						</Grid>
						<Grid item>
							<img
								className={classes.buro}
								src="/assets/images/buro.svg"
								alt="Logo de Buró de Entidades Financieras"
							/>

							<img
								className={classes.buroWhite}
								src="/assets/images/buro_white.svg"
								alt="Logo de la Comisión Nacional Bancaria y de Valores"
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* <div className={classes.backgroundWhite} /> */}
			<Grid item md={6} sm={12} className={classes.imageContainer}>
				<img
					className={classes.image}
					src="/assets/images/hero_image.webp"
					//srcSet="/assets/images/hero-home-lg.webp 1920w"
					alt="Un crédito hipotecario sin complicaciones"
				//sizes="(min-width: 1920px) 1920px"
				/>

			</Grid>
		</Grid>
	)
}