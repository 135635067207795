import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./HowItWorks.style";
import { Button } from "../../";

export function HowItWorks() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid item container justifyContent="center">
				<Typography
					color="primary"
					data-sal="slide-up"
					data-sal-delay="0"
					data-sal-duration="1000"
					data-sal-easing="ease"
					className={classes.title}
					component="h2"
				>
					¿Cómo funciona?
				</Typography>
			</Grid>
			<Grid item container className={classes.stepsContainer} >
				<Grid
					item
					container
					direction="column"
					className={classes.item}
					data-sal="slide-left"
					data-sal-delay="0"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					<Grid item className={classes.iconContainer}>
						<img
							className={classes.image}
							src="/assets/images/complete_icon.svg"
							alt="Completa el formulario"
						/>
						<div className={classes.line} />
					</Grid>
					<Grid item container direction="column" className={classes.infoContainer}>
						<Typography className={classes.itemTitle}>Completa el formulario</Typography>
						<Typography className={classes.itemDescription}>Completa un breve formulario y recibe tu precalificación.</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					container
					direction="column"
					className={classes.item}
					data-sal="slide-left"
					data-sal-delay="150"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					<Grid item className={classes.iconContainer}>
						<img
							className={classes.image}
							src="/assets/images/icono-asesoria.svg"
							alt="Asesoría y documentos"
						/>
						<div className={classes.line} />
					</Grid>
					<Grid item container direction="column" className={classes.infoContainer}>
						<Typography className={classes.itemTitle}>Asesoría y documentos</Typography>
						<Typography className={classes.itemDescription}>Recibe asesoría de nuestro ejecutivo y reúne tus documentos.</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					container
					direction="column"
					className={classes.item}
					data-sal="slide-left"
					data-sal-delay="300"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					<Grid item className={classes.iconContainer}>
						<img
							className={classes.image}
							src="/assets/images/icono-autorizacion.svg"
							alt="Autorización"
						/>
						<div className={classes.line} />
					</Grid>
					<Grid item container direction="column" className={classes.infoContainer}>
						<Typography className={classes.itemTitle}>Autorización</Typography>
						<Typography className={classes.itemDescription}>Evaluamos tu solicitud y te damos una respuesta en menos de 48 hrs.</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					container
					direction="column"
					className={classes.item}
					data-sal="slide-left"
					data-sal-delay="450"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					<Grid item className={classes.iconContainer}>
						<img
							className={classes.image}
							src="/assets/images/icono-valuamos.svg"
							alt="Valuamos y escrituramos"
						/>
						<div className={classes.line} />
					</Grid>
					<Grid item container direction="column" className={classes.infoContainer}>
						<Typography className={classes.itemTitle}>Valuamos y escrituramos</Typography>
						<Typography className={classes.itemDescription}>Reunimos los documentos del inmueble, se valua y comienza la formalización.</Typography>
					</Grid>
				</Grid>

				<Grid
					item
					container
					direction="column"
					data-sal="slide-left"
					data-sal-delay="650"
					data-sal-duration="1000"
					data-sal-easing="ease"
					className={classes.item}
				>
					<Grid item className={classes.iconContainer}>
						<img
							className={classes.image}
							src="/assets/images/icono-firma.svg"
							alt="Firma tu crédito"
						/>
					</Grid>
					<Grid item container direction="column" className={classes.infoContainer}>
						<Typography className={classes.itemTitle}>Recibe tu crédito</Typography>
						<Typography className={classes.itemDescription}>¡Firma y recibe tu crédito hipotecario!</Typography>
					</Grid>
				</Grid>

			</Grid>

			<Grid item container justifyContent="center" className={classes.moreButtonContainer}>
				<Button
					variant="outlined"
					color="primary"
					to="/como-funciona"
					className={classes.moreButton}>
					Conoce más
				</Button>
			</Grid>

		</Grid>
	)
}