import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root:{
		backgroundImage: 'url("/assets/images/bg_higlights.webp")',
		backgroundColor: theme.palette.primary.main
	},
	title: {
		fontSize: 50,
		lineHeight: "64px",
		fontWeight: "bold",
		color: 'white',
		textAlign: 'center',
		marginTop: 64,
		marginBottom: 8,
		[theme.breakpoints.down('md')]: {
			fontSize: 32,
			lineHeight: "40px",
			marginTop: 90,
			marginBottom: 0,
		}
	},
	subtitle:{
		fontSize:24,
		lineHeight: "32px",
		color: 'white',
		textAlign: 'center',
		marginBottom: 48,
		[theme.breakpoints.down('md')]: {
			fontSize: 22,
			lineHeight: "32px",
			padding: '0 16px'
		}
	},
	highlightsContainer:{
		marginBottom: 64,
		gap: 15,
		[theme.breakpoints.down('md')]:{
			flexDirection: 'column',
			alignItems:'center',
			gap: 30,
			marginBottom: 164,

		}
	},
	highlightsDivider:{
		width: 1,
		height: 96,
		backgroundColor: "white",
		[theme.breakpoints.down('md')]: {
			width: 255,
			height: 1,
		}
	},
	highlightsTitle:{
		fontSize: 48,
		lineHeight: "64px",
		fontWeight: "bold",
		color: 'white',
		textAlign: 'center',
	},
	highlightsSubtitle:{
		fontSize: 18,
		lineHeight: "32px",
		color: 'white',
		textAlign: 'center',
	}
}))