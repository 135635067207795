import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./CreditExperience.styles";
import { Button, Link } from "../..";
import clsx from "clsx";

export function CreditExperince() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid
				item
				container
				justifyContent="center"
				data-sal="slide-up"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Typography
					className={classes.title}
					color="primary"
					component="h2"
				>
					Elige tu experiencia de crédito
				</Typography>
			</Grid>
			<Grid item container className={classes.cardsContainer}>
				<Grid
					item
					className={classes.cardItem}
					data-sal="slide-right"
					data-sal-delay="0"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					{/* <Link to="/creditos/#compra-una-casa"> */}
					<Card elevation={0} className={classes.card}>
						<Link to="/creditos/#compra-una-casa">

							<div className={classes.imageContainer}>
								<img
									className={classes.image}
									src="/assets/images/purchase_house.webp"
									alt="Crédito para comprar casa o departamento"
									srcSet="/assets/images/comprar_casa_md.webp 600w"
									sizes="(max-width: 1023) 600px"
								/>
							</div>
						</Link>

						<Grid container className={classes.cardContainer}>
							<Link to="/creditos/#compra-una-casa">

								<Grid item>
									<Typography color="primary" className={classes.cardTitle} component="h3"> Crédito para comprar casa o departamento </Typography>
								</Grid>
								<Grid item >
									<ul className={classes.cardList}>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Te prestamos hasta el 90% del Valor de la Vivienda.
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Crédito desde $600 mil pesos.
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>Tasas desde el 9.9% </Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Solicitud 100% digital, fácil y rápido.
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Asesoría hipotecaria gratuita.
											</Typography>
										</li>
									</ul>

								</Grid>
							</Link>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								className={classes.cardActions}
							>
								<Grid item>
									<Link to="/creditos/#compra-una-casa">
										<Typography color="primary" className={classes.cardSeeMore}>Ver más <img src="/assets/images/arrow_right.svg" alt="" />  </Typography>
									</Link>
								</Grid>
								<Grid item>
									<Button
										to={`${process.env.GATSBY_KREDI_APP_BASE_URL}/`}
										useNativeAnchor
										color="primary"
										variant="contained"
										size="medium"
										target="_self"
									>
										Solicitar ahora
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Card>
					{/* </Link> */}

				</Grid>
				<Grid
					item
					className={classes.cardItem}
					data-sal="slide-left"
					data-sal-delay="0"
					data-sal-duration="1000"
					data-sal-easing="ease"
				>
					<Card elevation={0} className={classes.card}>
						<Link to="/creditos/#mejora-de-hipoteca">

							<div className={classes.improveImageContainer}>
								<img
									className={classes.improveImage}
									src="/assets/images/improve_your_credit.webp"
									alt="Mejora tu hipoteca actual"
								// srcSet="/assets/images/mejora_tu_hipoteca_md.jpg 600w"
								// sizes="(max-width: 1024px) and (min-width: 500px) 600px"
								/>
							</div>
						</Link>

						<Grid container className={classes.cardContainer}>
							<Link to="/creditos/#mejora-de-hipoteca">

								<Grid item>
									<Typography
										color="primary"
										className={clsx(classes.cardTitle, classes.marginBottomTitle)}
										component="h3"
									>
										Mejora tu hipoteca actual
									</Typography>
								</Grid>
								<Grid item >
									<ul className={classes.cardList}>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Mejoramos tu hipoteca actual
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Crédito desde $600 mil pesos.
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Tasas desde el 9.5%
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Solicitud 100% digital, fácil y rápido.
											</Typography>
										</li>
										<li className={classes.cardListItem}>
											<Typography className={classes.cardDecription}>
												Asesoría hipotecaria gratuita.
											</Typography>
										</li>
									</ul>

								</Grid>
							</Link >

							<Grid
								container
								direction="row"
								justifyContent="space-between"
								className={classes.cardActions}
							>
								<Grid item>
									<Link to="/creditos/#compra-una-casa">
										<Typography color="primary" className={classes.cardSeeMore}>Ver más <img src="/assets/images/arrow_right.svg" alt="" />  </Typography>
									</Link>
								</Grid>
								<Grid item>
									<Button
										to={`${process.env.GATSBY_KREDI_APP_BASE_URL}/`}
										useNativeAnchor
										color="primary"
										variant="contained"
										size="medium"
										target="_self"
									>
										Solicitar ahora
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Card>

				</Grid>
			</Grid>
		</Grid >
	)
}