import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "#F9FAFC"
	},
	title: {
		fontSize: 50,
		lineHeight: "64px",
		fontWeight: "bold",
		paddingTop: 104,
		paddingBottom: 52,
		[theme.breakpoints.down('md')]: {
			textAlign: 'center',
			paddingTop: 40,
			fontSize: 32,
			lineHeight: "40px",
		}
	},
	stepsContainer: {
		gap: 40,
		padding: '0 70px',
		paddingRight: 50,
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '0 16px',
			flexDirection: 'column'

		},
		[theme.breakpoints.between(1280, 1440)]: {
			padding: '0px',
			gap: 30,
			justifyContent: 'flex-end',

		}

	},
	item: {
		gap: 11,
		width: 231,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'row',
			flexWrap: 'nowrap',
			width: '100%'

		}
	},
	image: {
		width: 34,
		height: 34
	},
	iconContainer: {
		display: "flex",
		alignItems: 'center',
		gap: 16,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column'

		}
	},
	line: {
		height: 1,
		width: 162,
		backgroundColor: theme.palette.primary.main,
		[theme.breakpoints.down('md')]: {
			//transform:'rotate(-90deg)',
			height: 135,
			width: 1,
		}
	},
	infoContainer: {
		gap: 4,
	},
	itemTitle: {
		fontWeight: 'bold',
		lineHeight: '30px',
		fontSize: 18,
		[theme.breakpoints.down('md')]: {
			fontSize: 20,
			lineHeight: '28px',


		}
	},
	itemDescription: {
		lineHeight: '20px',
		fontSize: 14,
		[theme.breakpoints.down('md')]: {
			fontSize: 18,
			lineHeight: '26px',


		}
	},
	moreButtonContainer: {
		marginBottom: 103,
		marginTop: 100,
		[theme.breakpoints.down('md')]: {
			marginTop: 46,
			marginBottom: 64,


		}
	},
	moreButton: {
		backgroundColor: '#fff'
	}
}))