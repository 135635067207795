import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Highlights.styles";
import { motion } from "framer-motion";

export function Highlights() {
	const classes = useStyles()
	return (
		<Grid
			container
			direction="column"
			alignItems="center"
			justifyContent="center"
			className={classes.root}
		// data-sal="slide-up"
		// data-sal-delay="0"
		// data-sal-duration="1000"
		// data-sal-easing="ease"

		>
			<motion.div
				initial={{ opacity: 0, y: 140 }}
				transition={{ duration: 1.6, type: "tween" }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
			>
				<Grid item>
					<Typography
						className={classes.title}
						component="h2"
					>
						Sabemos de créditos
					</Typography>
				</Grid>
				<Grid item>
					<Typography
						className={classes.subtitle}

					>
						Nuestros logros hablan por sí mismos
					</Typography>
				</Grid>
				<Grid item container justifyContent="center" className={classes.highlightsContainer}>
					<Grid item>
						<Typography className={classes.highlightsTitle}>105+</Typography>
						<Typography

							className={classes.highlightsSubtitle}>
							Familias confiaron en nosotros
						</Typography>
					</Grid>
					<div className={classes.highlightsDivider} />
					<Grid item>
						<Typography className={classes.highlightsTitle}>100+</Typography>
						<Typography

							className={classes.highlightsSubtitle}
						>
							Millones de pesos en créditos
						</Typography>
					</Grid>
				</Grid>
			</motion.div>

		</Grid>
	)
}